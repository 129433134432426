














import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class ContactFormSimple extends Vue {
    get url() {
        return process.env.VUE_APP_OPENAPI_URL;
    }
}
